
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { useEffect } from 'react';
import { useRouter } from 'next/router';
export default function Page() {
    const router = useRouter();
    useEffect(() => {
        router.push('/');
    });
    return null;
}
// export const getStaticProps = async () => {
//     if (process.env.npm_lifecycle_event === 'build')
//         return {
//             props: {
//                 title: '404',
//                 content: '404',
//             },
//         }
//     return {
//         redirect: {
//             destination: '/',
//             permanent: true,
//         },
//     }
// }

    async function __Next_Translate__getStaticProps__193bbee8121__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: typeof __i18nConfig.loadLocaleFrom === 'function' ? __i18nConfig.loadLocaleFrom : (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__193bbee8121__ as getStaticProps }
  